$ki-embed-font: true;
$ki-icon-size: $kendo-icon-size;

@import "@progress/kendo-font-icons/scss/index.scss";

@mixin kendo-icon--layout-base() {

    @include kendo-icon-styles();

    // Icon wrap
    .k-icon-wrap {
        flex: none;
        display: inline-flex;
        flex-flow: row nowrap;
        gap: 0;
        align-items: center;
        align-self: flex-start;
        vertical-align: middle;
        position: relative;

        &::before {
            content: "\200b";
            width: 0;
            overflow: hidden;
            flex: none;
            display: inline-block;
            vertical-align: top;
        }
    }

    // kendo-icon-wrapper component
    .k-icon-wrapper-host {
        display: contents;
    }

    .k-icon-with-modifier {
        position: relative;
        margin: .25em;
    }

    .k-icon-modifier {
        position: absolute;
        font-size: .5em;
        bottom: 0;
        right: 0;
        margin: 0 -.5em -.5em 0;
    }

    .k-i-none::before {
        content: "";
        display: none;
    }

    .k-icon-action {
        padding: $kendo-icon-padding;
        cursor: pointer;
    }

    // RTL icons
    .k-rtl .k-i-indent-increase,
    .k-rtl .k-i-indent-decrease,
    .k-rtl .k-i-caret-alt-right,
    .k-rtl .k-i-caret-alt-left,
    [dir="rtl"] .k-i-caret-alt-right,
    [dir="rtl"] .k-i-caret-alt-left {
        transform: scaleX(-1);
    }

    .k-sprite {
        display: inline-block;
        width: 16px;
        height: 16px;
        overflow: hidden;
        background-repeat: no-repeat;
        font-size: 0;
        line-height: 0;
        text-align: center;
    }

    .k-image {
        display: inline-block;
    }
}


@mixin kendo-icon--layout() {
    @include kendo-icon--layout-base();
}
