// Rating

$kendo-rating-font-family: $kendo-font-family !default;
$kendo-rating-font-size: $kendo-font-size-md !default;
$kendo-rating-line-height: $kendo-line-height-md !default;

$kendo-rating-container-margin-x: $kendo-padding-sm-x !default;

$kendo-rating-item-padding-x: $kendo-padding-sm-x !default;
$kendo-rating-item-padding-y: $kendo-padding-md-y !default;

$kendo-rating-label-margin-x: $kendo-padding-md-x !default;
$kendo-rating-label-margin-y: $kendo-padding-md-y !default;
$kendo-rating-label-line-height: $kendo-line-height-lg !default;

$kendo-rating-icon-size: ($kendo-icon-size * 1.5) !default;

$kendo-rating-icon-text: $kendo-component-text !default;
$kendo-rating-icon-selected-text: $kendo-selected-bg !default;
$kendo-rating-icon-hover-text: $kendo-selected-bg !default;
$kendo-rating-icon-focus-text: $kendo-selected-bg !default;

$kendo-rating-icon-focus-shadow: 0 2px 4px rgba( $kendo-color-black, .1 ) !default;
$kendo-rating-icon-focus-selected-shadow: 0 2px 4px rgba( $kendo-color-black, .1) !default;
