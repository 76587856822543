@mixin kendo-window--theme-base() {

    // Window
    .k-window {
        @include fill(
            $kendo-window-text,
            $kendo-window-bg,
            $kendo-window-border
        );
        @include box-shadow( $kendo-window-shadow );
    }

    .k-window {
        &:focus,
        &.k-focus {
            @include focus-indicator( $kendo-window-focus-shadow );
        }
    }


    // Titlebar
    .k-window-titlebar {
        @include fill(
            $kendo-window-titlebar-text,
            $kendo-window-titlebar-bg,
            $kendo-window-titlebar-border,
            $kendo-window-titlebar-gradient
        );
    }

    // Window theme colors
    @each $name, $color in $kendo-window-theme-colors {
        .k-window-#{$name} .k-window-titlebar {
            color: k-contrast-legacy( $color );
            background-color: $color;
        }
    }

}


@mixin kendo-window--theme() {
    @include kendo-window--theme-base();
}
