@import "../list/_variables.scss";


// Pager

/// Horizontal padding of the pager according to the pager size.
/// @group pager
$kendo-pager-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-pager-sm-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-pager-md-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-pager-lg-padding-x: k-map-get( $kendo-spacing, 2.5 ) !default;
/// Vertical padding of the pager according to the pager size.
/// @group pager
$kendo-pager-padding-y: $kendo-pager-padding-x !default;
$kendo-pager-sm-padding-y: $kendo-pager-sm-padding-x !default;
$kendo-pager-md-padding-y: $kendo-pager-md-padding-x !default;
$kendo-pager-lg-padding-y: $kendo-pager-lg-padding-x !default;

/// Minimum width of the pager items according to the pager size.
/// @group pager
$kendo-pager-sm-item-min-width: $kendo-button-sm-calc-size !default;
$kendo-pager-md-item-min-width: $kendo-button-md-calc-size !default;
$kendo-pager-lg-item-min-width: $kendo-button-lg-calc-size !default;

/// Margin between pager item groups according to the pager size.
/// @group pager
$kendo-pager-sm-item-group-spacing: k-map-get( $kendo-spacing, 3 ) !default;
$kendo-pager-md-item-group-spacing: k-map-get( $kendo-spacing, 3.5 ) !default;
$kendo-pager-lg-item-group-spacing: k-map-get( $kendo-spacing, 4 ) !default;

/// Border width of the pager.
/// @group pager
$kendo-pager-border-width: 1px !default;
/// Font family of the pager.
/// @group pager
$kendo-pager-font-family: $kendo-font-family !default;
/// Font size of the pager.
/// @group pager
$kendo-pager-font-size: $kendo-font-size-md !default;
/// Line height of the pager.
/// @group pager
$kendo-pager-line-height: $kendo-line-height-md !default;
/// Background color of the pager.
/// @group pager
$kendo-pager-bg: $kendo-component-header-bg !default;
/// Text color of the pager.
/// @group pager
$kendo-pager-text: $kendo-component-header-text !default;
/// Border color of the pager.
/// @group pager
$kendo-pager-border: $kendo-component-border !default;

/// Background color of the focused pager.
/// @group pager
$kendo-pager-focus-bg: null !default;
/// Box shadow of the focused pager.
/// @group pager
$kendo-pager-focus-shadow: $kendo-focus-shadow !default;

/// Spacing between the pager sections.
/// @group pager
$kendo-pager-section-spacing: k-map-get( $kendo-spacing, 4 ) !default;

/// Border width of the pager items.
/// @group pager
$kendo-pager-item-border-width: 0px !default;
/// Border radius of the pager items.
/// @group pager
$kendo-pager-item-border-radius: null !default;
/// Spacing around the pager items.
/// @group pager
$kendo-pager-item-spacing: null !default;
/// Background color of the pager items.
/// @group pager
$kendo-pager-item-bg: null !default;
/// Text color of the pager items.
/// @group pager
$kendo-pager-item-text: null !default;
/// Border color of the pager items.
/// @group pager
$kendo-pager-item-border: null !default;

/// Background color of the hovered pager items.
/// @group pager
$kendo-pager-item-hover-bg: $kendo-list-item-hover-bg !default;
/// Text color of the hovered pager items.
/// @group pager
$kendo-pager-item-hover-text: $kendo-list-item-hover-text !default;
/// Border color of the hovered pager items.
/// @group pager
$kendo-pager-item-hover-border: null !default;
/// Background color of the selected pager items.
/// @group pager
$kendo-pager-item-selected-bg: $kendo-list-item-selected-bg !default;
/// Text color of the selected pager items.
/// @group pager
$kendo-pager-item-selected-text: $kendo-list-item-selected-text !default;
/// Border color of the selected pager items.
/// @group pager
$kendo-pager-item-selected-border: null !default;
/// Opacity of the focused pager items.
/// @group pager
$kendo-pager-item-focus-opacity: null !default;
/// Background color of the focused pager items.
/// @group pager
$kendo-pager-item-focus-bg: transparent !default;
/// Box shadow of the focused pager items.
/// @group pager
$kendo-pager-item-focus-shadow: $kendo-list-item-focus-shadow !default;

/// Border radius of the pager numbers.
/// @group pager
$kendo-pager-number-border-radius: null !default;

/// Width of the pager inputs.
/// @group pager
$kendo-pager-input-width: 5em !default;

/// Width of the pager dropdowns according to the pager size.
/// @group pager
$kendo-pager-sm-dropdown-width: 5em !default;
$kendo-pager-md-dropdown-width: 5em !default;
$kendo-pager-lg-dropdown-width: 5em !default;

/// Sizes map of the pager.
/// @group pager
$kendo-pager-sizes: (
    sm: (
        padding-x: $kendo-pager-sm-padding-x,
        padding-y: $kendo-pager-sm-padding-y,
        item-group-spacing: $kendo-pager-sm-item-group-spacing,
        item-min-width: $kendo-pager-sm-item-min-width,
        pager-dropdown-width: $kendo-pager-sm-dropdown-width
    ),
    md: (
        padding-x: $kendo-pager-md-padding-x,
        padding-y: $kendo-pager-md-padding-y,
        item-group-spacing: $kendo-pager-md-item-group-spacing,
        item-min-width: $kendo-pager-md-item-min-width,
        pager-dropdown-width: $kendo-pager-md-dropdown-width
    ),
    lg: (
        padding-x: $kendo-pager-lg-padding-x,
        padding-y: $kendo-pager-lg-padding-y,
        item-group-spacing: $kendo-pager-lg-item-group-spacing,
        item-min-width: $kendo-pager-lg-item-min-width,
        pager-dropdown-width: $kendo-pager-lg-dropdown-width
    )
) !default;
