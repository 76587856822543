@import "./images/alpha-slider-bgr.scss";


// ColorGradient
$kendo-color-gradient-spacer: k-map-get( $kendo-spacing, 3 ) !default;

$kendo-color-gradient-width: 272px !default;
$kendo-color-gradient-border-width: 1px !default;
$kendo-color-gradient-border-radius: $kendo-border-radius-md !default;
$kendo-color-gradient-padding-y: $kendo-color-gradient-spacer !default;
$kendo-color-gradient-padding-x: $kendo-color-gradient-padding-y !default;
$kendo-color-gradient-gap: $kendo-color-gradient-spacer !default;
$kendo-color-gradient-font-family: $kendo-font-family !default;
$kendo-color-gradient-font-size: $kendo-font-size-md !default;
$kendo-color-gradient-line-height: $kendo-line-height-md !default;
$kendo-color-gradient-bg: $kendo-component-bg !default;
$kendo-color-gradient-text: $kendo-component-text !default;
$kendo-color-gradient-border: $kendo-component-border !default;

$kendo-color-gradient-focus-border: null !default;
$kendo-color-gradient-focus-shadow: 1px 1px 7px 1px rgba(0, 0, 0, .3) !default;

$kendo-color-gradient-canvas-border-radius: $kendo-border-radius-md !default;
$kendo-color-gradient-canvas-gap: $kendo-color-gradient-spacer !default;
$kendo-color-gradient-canvas-rectangle-height: 180px !default;

$kendo-color-gradient-slider-track-size: 10px !default;
$kendo-color-gradient-slider-border-radius: 10px !default;
$kendo-color-gradient-slider-draghandle-border-width: 3px !default;

$kendo-color-gradient-slider-vertical-size: 180px !default;
$kendo-color-gradient-slider-horizontal-size: 100% !default;

$kendo-color-gradient-draghandle-width: 14px !default;
$kendo-color-gradient-draghandle-height: 14px !default;
$kendo-color-gradient-draghandle-border-width: 1px !default;
$kendo-color-gradient-draghandle-border-radius: 50% !default;
$kendo-color-gradient-draghandle-bg: transparent !default;
$kendo-color-gradient-draghandle-text: null !default;
$kendo-color-gradient-draghandle-border: rgba( $kendo-color-white, .8) !default;
$kendo-color-gradient-draghandle-shadow: 0 1px 4px rgba(0, 0, 0, .5) !default;
$kendo-color-gradient-draghandle-focus-shadow: 0 1px 4px black !default;
$kendo-color-gradient-draghandle-hover-shadow: $kendo-color-gradient-draghandle-focus-shadow !default;

$kendo-color-gradient-canvas-draghandle-margin-y: - k-math-div( $kendo-color-gradient-draghandle-height, 2 ) !default;
$kendo-color-gradient-canvas-draghandle-margin-x: - k-math-div( $kendo-color-gradient-draghandle-width, 2 ) !default;

$kendo-color-gradient-input-width: 46px !default;
$kendo-color-gradient-input-gap: k-math-div( $kendo-color-gradient-spacer, 1.5 ) !default;
$kendo-color-gradient-input-label-gap: k-math-div( $kendo-color-gradient-spacer, 3 ) !default;
$kendo-color-gradient-input-label-text: $kendo-subtle-text !default;

$kendo-color-gradient-contrast-ratio-font-weight: $kendo-font-weight-bold !default;
$kendo-color-gradient-contrast-spacer: k-math-div( $kendo-color-gradient-spacer, 1.5 ) !default;
