// Panelbar

$kendo-panelbar-padding-x: 0px !default;
$kendo-panelbar-padding-y: 0px !default;
$kendo-panelbar-font-family: $kendo-font-family !default;
$kendo-panelbar-font-size: $kendo-font-size-md !default;
$kendo-panelbar-line-height: $kendo-line-height-md !default;
$kendo-panelbar-border-width: 1px !default;
$kendo-panelbar-border-style: solid !default;
$kendo-panelbar-item-border-width: 1px !default;
$kendo-panelbar-item-border-style: solid !default;

$kendo-panelbar-header-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-panelbar-header-padding-y: k-map-get( $kendo-spacing, 3 ) !default;

$kendo-panelbar-item-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-panelbar-item-padding-y: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-panelbar-item-level-count: 4 !default;

$kendo-panelbar-bg: $kendo-component-bg !default;
$kendo-panelbar-text: $kendo-component-text !default;
$kendo-panelbar-border: $kendo-component-border !default;

$kendo-panelbar-header-bg: $kendo-panelbar-bg !default;
$kendo-panelbar-header-text: $kendo-link-text !default;
$kendo-panelbar-header-border: null !default;
$kendo-panelbar-header-gradient: null !default;

$kendo-panelbar-header-hover-bg: k-try-shade( $kendo-panelbar-header-bg, .5 ) !default;
$kendo-panelbar-header-hover-text: null !default;
$kendo-panelbar-header-hover-border: null !default;
$kendo-panelbar-header-hover-gradient: null !default;

$kendo-panelbar-header-focus-bg: null !default;
$kendo-panelbar-header-focus-text: null !default;
$kendo-panelbar-header-focus-border: null !default;
$kendo-panelbar-header-focus-gradient: null !default;
$kendo-panelbar-header-focus-shadow: $kendo-list-item-focus-shadow !default;

$kendo-panelbar-header-hover-focus-bg: null !default;
$kendo-panelbar-header-hover-focus-text: null !default;
$kendo-panelbar-header-hover-focus-border: null !default;
$kendo-panelbar-header-hover-focus-gradient: null !default;

$kendo-panelbar-header-selected-bg: $kendo-selected-bg !default;
$kendo-panelbar-header-selected-text: $kendo-selected-text !default;
$kendo-panelbar-header-selected-border: null !default;
$kendo-panelbar-header-selected-gradient: null !default;

$kendo-panelbar-header-selected-hover-bg: k-try-shade( $kendo-panelbar-header-selected-bg ) !default;
$kendo-panelbar-header-selected-hover-text: null !default;
$kendo-panelbar-header-selected-hover-border: null !default;
$kendo-panelbar-header-selected-hover-gradient: null !default;

$kendo-panelbar-header-selected-focus-bg: null !default;
$kendo-panelbar-header-selected-focus-text: null !default;
$kendo-panelbar-header-selected-focus-border: null !default;
$kendo-panelbar-header-selected-focus-gradient: null !default;

$kendo-panelbar-header-selected-hover-focus-bg: null !default;
$kendo-panelbar-header-selected-hover-focus-text: null !default;
$kendo-panelbar-header-selected-hover-focus-border: null !default;
$kendo-panelbar-header-selected-hover-focus-gradient: null !default;

$kendo-panelbar-item-hover-bg: k-try-shade( $kendo-panelbar-bg, .5 ) !default;
$kendo-panelbar-item-hover-text: null !default;
$kendo-panelbar-item-hover-border: null !default;
$kendo-panelbar-item-hover-gradient: null !default;

$kendo-panelbar-item-focus-bg: null !default;
$kendo-panelbar-item-focus-text: null !default;
$kendo-panelbar-item-focus-border: null !default;
$kendo-panelbar-item-focus-gradient: null !default;
$kendo-panelbar-item-focus-shadow: $kendo-list-item-focus-shadow !default;

$kendo-panelbar-item-hover-focus-bg: null !default;
$kendo-panelbar-item-hover-focus-text: null !default;
$kendo-panelbar-item-hover-focus-border: null !default;
$kendo-panelbar-item-hover-focus-gradient: null !default;

$kendo-panelbar-item-selected-bg: $kendo-selected-bg !default;
$kendo-panelbar-item-selected-text: $kendo-selected-text !default;
$kendo-panelbar-item-selected-border: null !default;
$kendo-panelbar-item-selected-gradient: null !default;

$kendo-panelbar-item-selected-hover-bg: k-try-shade( $kendo-panelbar-item-selected-bg ) !default;
$kendo-panelbar-item-selected-hover-text: null !default;
$kendo-panelbar-item-selected-hover-border: null !default;
$kendo-panelbar-item-selected-hover-gradient: null !default;

$kendo-panelbar-item-selected-focus-bg: null !default;
$kendo-panelbar-item-selected-focus-text: null !default;
$kendo-panelbar-item-selected-focus-border: null !default;
$kendo-panelbar-item-selected-focus-gradient: null !default;

$kendo-panelbar-item-selected-hover-focus-bg: null !default;
$kendo-panelbar-item-selected-hover-focus-text: null !default;
$kendo-panelbar-item-selected-hover-focus-border: null !default;
$kendo-panelbar-item-selected-hover-focus-gradient: null !default;

$kendo-panelbar-header-expanded-bg: null !default;
$kendo-panelbar-header-expanded-text: null !default;
$kendo-panelbar-header-expanded-border: null !default;
$kendo-panelbar-header-expanded-gradient: null !default;
