// Upload

$kendo-upload-border-width: 1px !default;
$kendo-upload-font-family: $kendo-font-family !default;
$kendo-upload-font-size: $kendo-font-size-md !default;
$kendo-upload-line-height: $kendo-line-height-md !default;
$kendo-upload-max-height: 300px !default;

$kendo-upload-bg: $kendo-component-bg !default;
$kendo-upload-text: $kendo-component-text !default;
$kendo-upload-border: $kendo-component-border !default;

$kendo-upload-dropzone-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-upload-dropzone-padding-y: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-upload-dropzone-bg: $kendo-component-header-bg !default;
$kendo-upload-dropzone-text: $kendo-component-header-text !default;
$kendo-upload-dropzone-border: $kendo-upload-border !default;
$kendo-upload-dropzone-hover-bg: $kendo-hover-bg !default;

$kendo-upload-status-text: $kendo-subtle-text !default;
$kendo-upload-status-text-opacity: null !default;

$kendo-upload-item-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-upload-item-padding-y: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-upload-multiple-items-spacing: 12px !default;

$kendo-upload-validation-font-size: 11px !default;
$kendo-upload-icon-spacing: $kendo-icon-spacing !default;
$kendo-upload-icon-color: $kendo-subtle-text !default;

$kendo-upload-item-image-width: 30px !default;
$kendo-upload-item-image-height: 30px !default;
$kendo-upload-item-image-border: 0px !default; // TODO: remove

$kendo-upload-group-icon-size: $kendo-icon-size-xxl !default;

$kendo-upload-progress-thickness: 2px !default;
$kendo-upload-progress-bg: $kendo-color-info !default;

$kendo-upload-success-bg: $kendo-color-success !default;
$kendo-upload-success-text: $kendo-color-success !default;

$kendo-upload-error-bg: $kendo-color-error !default;
$kendo-upload-error-text: $kendo-color-error !default;
$kendo-upload-error-border: $kendo-color-error !default;

$kendo-upload-focus-shadow: 0 0 0 2px rgba(0, 0, 0, .13) !default;
