@mixin kendo-tooltip--theme-base() {

    .k-tooltip-wrapper {
        .k-tooltip {
            box-shadow: $kendo-popup-shadow;
        }
    }


    // Default tooltip
    .k-tooltip {
        @include fill(
            $kendo-tooltip-text,
            $kendo-tooltip-bg,
            $kendo-tooltip-border
        );

        .k-callout {
            color: $kendo-tooltip-bg;
        }
    }


    // Tooltip variants
    .k-tooltip-primary {
        @include fill(
            $kendo-tooltip-primary-text,
            $kendo-tooltip-primary-bg,
            $kendo-tooltip-primary-border
        );

        .k-callout {
            color: $kendo-tooltip-primary-bg;
        }
    }
    .k-tooltip-info {
        @include fill(
            $kendo-tooltip-info-text,
            $kendo-tooltip-info-bg,
            $kendo-tooltip-info-border
        );

        .k-callout {
            color: $kendo-tooltip-info-bg;
        }
    }
    .k-tooltip-success {
        @include fill(
            $kendo-tooltip-success-text,
            $kendo-tooltip-success-bg,
            $kendo-tooltip-success-border
        );

        .k-callout {
            color: $kendo-tooltip-success-bg;
        }
    }
    .k-tooltip-warning {
        @include fill(
            $kendo-tooltip-warning-text,
            $kendo-tooltip-warning-bg,
            $kendo-tooltip-warning-border
        );

        .k-callout {
            color: $kendo-tooltip-warning-bg;
        }
    }
    .k-tooltip-error {
        @include fill(
            $kendo-tooltip-error-text,
            $kendo-tooltip-error-bg,
            $kendo-tooltip-error-border
        );

        .k-callout {
            color: $kendo-tooltip-error-bg;
        }
    }

}


@mixin kendo-tooltip--theme() {
    @include kendo-tooltip--theme-base();
}
