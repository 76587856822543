@import "./images/slider-h.scss";
@import "./images/slider-v.scss";


// Slider
$kendo-slider-size: 200px !default;
$kendo-slider-tick-size: null !default;
$kendo-slider-alt-size: 30px !default;

$kendo-slider-font-family: $kendo-font-family !default;
$kendo-slider-font-size: $kendo-font-size-md !default;
$kendo-slider-line-height: $kendo-line-height-md !default;

$kendo-slider-button-offset: 10px !default;
$kendo-slider-button-size: ($kendo-slider-alt-size - 2) !default;
$kendo-slider-button-spacing: ($kendo-slider-button-size + $kendo-slider-button-offset) !default;
$kendo-slider-button-focus-shadow: null !default;

$kendo-slider-track-thickness: 4px !default;
$kendo-slider-draghandle-size: 14px !default;
$kendo-slider-draghandle-border-width: 1px !default;
$kendo-slider-draghandle-active-scale: 1 !default;
$kendo-slider-draghandle-active-size: null !default;

$kendo-slider-draghandle-bg: $kendo-color-primary !default;
$kendo-slider-draghandle-text: k-contrast-legacy( $kendo-color-primary ) !default;
$kendo-slider-draghandle-border: $kendo-color-primary !default;
$kendo-slider-draghandle-gradient: null !default;

$kendo-slider-draghandle-hover-bg: k-try-shade( $kendo-color-primary , .5 ) !default;
$kendo-slider-draghandle-hover-text: null !default;
$kendo-slider-draghandle-hover-border: $kendo-color-primary !default;
$kendo-slider-draghandle-hover-gradient: null !default;

$kendo-slider-draghandle-pressed-bg: k-try-shade( $kendo-color-primary , 1.5 ) !default;
$kendo-slider-draghandle-pressed-text: null !default;
$kendo-slider-draghandle-pressed-border: k-try-shade( $kendo-color-primary , 1.5 ) !default;
$kendo-slider-draghandle-pressed-gradient: null !default;

$kendo-slider-draghandle-focus-shadow: 0 0 0 2px rgba( $kendo-color-primary , .3 ) !default;

$kendo-slider-transition-speed: .3s !default;
$kendo-slider-transition-function: ease-out !default;
$kendo-slider-draghandle-transition-speed: .4s !default;
$kendo-slider-draghandle-transition-function: cubic-bezier(.25, .8, .25, 1) !default;

$kendo-slider-track-bg: k-try-shade( $kendo-component-bg, 1 ) !default;
$kendo-slider-selection-bg: $kendo-color-primary !default;
$kendo-slider-disabled-opacity: null !default;
