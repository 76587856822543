@mixin kendo-badge--theme-base() {

    // Solid badges
    @each $name, $color in $kendo-theme-colors {
        .k-badge-solid-#{$name} {
            border-color: $color;
            color: k-contrast-legacy( $color );
            background-color: $color;
        }
    }

    // Outline badges
    .k-badge-outline {
        border-color: currentColor;
        background-color: $kendo-component-bg;
    }

    @each $name, $color in $kendo-theme-colors {
        .k-badge-outline-#{$name} {
            color: $color;
        }
    }

}


@mixin kendo-badge--theme() {
    @include kendo-badge--theme-base();
}
