// Treeview

/// Font family of the treeview component.
/// @group treeview
$kendo-treeview-font-family: $kendo-font-family !default;
/// Font size of the treeview component.
/// @group treeview
$kendo-treeview-font-size: $kendo-font-size-md !default;
$kendo-treeview-sm-font-size: $kendo-font-size-md !default;
$kendo-treeview-md-font-size: $kendo-font-size-md !default;
$kendo-treeview-lg-font-size: $kendo-font-size-lg !default;
/// Line height of the treeview component.
/// @group treeview
$kendo-treeview-line-height: $kendo-line-height-md !default;
$kendo-treeview-sm-line-height: $kendo-line-height-md !default;
$kendo-treeview-md-line-height: $kendo-line-height-md !default;
$kendo-treeview-lg-line-height: $kendo-line-height-lg !default;
/// Indentation of child groups in treeview component.
/// @group treeview
$kendo-treeview-indent: 16px !default;

/// Horizontal padding of treeview items.
/// @group treeview
$kendo-treeview-item-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-treeview-sm-item-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-treeview-md-item-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-treeview-lg-item-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
/// Vertical padding of treeview items.
/// @group treeview
$kendo-treeview-item-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-treeview-sm-item-padding-y: k-map-get( $kendo-spacing, 0.5 ) !default; // sass-lint:disable-line leading-zero
$kendo-treeview-md-item-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-treeview-lg-item-padding-y: k-map-get( $kendo-spacing, 2 ) !default;
/// Border width of treeview items.
/// @group treeview
$kendo-treeview-item-border-width: 0px !default;
/// Border radius of treeview items.
/// @group treeview
$kendo-treeview-item-border-radius: $kendo-border-radius-md !default;


/// The sizes of the treeview.
/// @group treeview
$kendo-treeview-sizes: (
    sm: (
        font-size: $kendo-treeview-sm-font-size,
        line-height: $kendo-treeview-sm-line-height,
        item-padding-x: $kendo-treeview-sm-item-padding-x,
        item-padding-y: $kendo-treeview-sm-item-padding-y
    ),
    md: (
        font-size: $kendo-treeview-md-font-size,
        line-height: $kendo-treeview-md-line-height,
        item-padding-x: $kendo-treeview-md-item-padding-x,
        item-padding-y: $kendo-treeview-md-item-padding-y
    ),
    lg: (
        font-size: $kendo-treeview-lg-font-size,
        line-height: $kendo-treeview-lg-line-height,
        item-padding-x: $kendo-treeview-lg-item-padding-x,
        item-padding-y: $kendo-treeview-lg-item-padding-y
    )
) !default;


/// Background color of the treeview component.
/// @group treeview
$kendo-treeview-bg: null !default;
/// Text color of the treeview component.
/// @group treeview
$kendo-treeview-text: $kendo-component-text !default;
/// Border color of the treeview component.
/// @group treeview
$kendo-treeview-border: null !default;

/// Background color of hovered treeview items.
/// @group treeview
$kendo-treeview-item-hover-bg: $kendo-hover-bg !default;
/// Text color of hovered treeview items.
/// @group treeview
$kendo-treeview-item-hover-text: $kendo-hover-text !default;
/// Border color of hovered treeview items.
/// @group treeview
$kendo-treeview-item-hover-border: null !default;
/// Background gradient of hovered treeview items.
/// @group treeview
$kendo-treeview-item-hover-gradient: null !default;

/// Background color of selected treeview items.
/// @group treeview
$kendo-treeview-item-selected-bg: $kendo-selected-bg !default;
/// Text color of selected treeview items.
/// @group treeview
$kendo-treeview-item-selected-text: $kendo-selected-text !default;
/// Border color of selected treeview items.
/// @group treeview
$kendo-treeview-item-selected-border: null !default;
/// Background gradient of selected treeview items.
/// @group treeview
$kendo-treeview-item-selected-gradient: null !default;

/// Box shadow of focused treeview items.
/// @group treeview
$kendo-treeview-item-focus-shadow: $kendo-focus-shadow !default;

/// Background color of load more.
/// @group treeview
$kendo-treeview-loadmore-bg: transparent !default;
/// Text color of load more.
/// @group treeview
$kendo-treeview-loadmore-text: $kendo-link-text !default;
/// Border color of load more.
/// @group treeview
$kendo-treeview-loadmore-border: null !default;

/// Background color of a hovered load more.
/// @group treeview
$kendo-treeview-loadmore-hover-bg: transparent !default;
/// Text color of a hovered load more.
/// @group treeview
$kendo-treeview-loadmore-hover-text: $kendo-link-hover-text !default;
/// Border color of a hovered load more.
/// @group treeview
$kendo-treeview-loadmore-hover-border: null !default;

/// Background color of a focused load more.
/// @group treeview
$kendo-treeview-loadmore-focus-bg: transparent !default;
/// Text color of a focused load more.
/// @group treeview
$kendo-treeview-loadmore-focus-text: $kendo-link-hover-text !default;
/// Border color of a focused load more.
/// @group treeview
$kendo-treeview-loadmore-focus-border: null !default;
/// Box shadow of a focused load more.
/// @group treeview
$kendo-treeview-loadmore-focus-shadow: $kendo-treeview-item-focus-shadow !default;
