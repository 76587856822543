.responsive-table {
  .field-label,.action-buttons{
    display: none;
  }
  &.table-with-filters,&.table-with-out-filters{
    @media all and (max-width: 580px) {
      background: var(--additional-page-bg);
      border: none;
      box-shadow: none;
      height: 100%;
    }
    @media all and (max-width: 991px) {
      .field-label{
        display: block;
        font-weight: 700;
        margin-right: 10px;
        @media all and (max-width: 580px) {
          color: var(--text-colors-title-300);
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          margin: 0 0 5px 0;
        }
      }
      .action-buttons{
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        button{
          width: 100%;
          background: var(--secondary-300) !important;
          color: var(--white) !important;
          position: relative !important;
          display: inherit;
          justify-content: center;
          cursor: pointer;
          font-weight: bold;
          font-size: var(--font-size-body);
          line-height: 16px;
          padding: 12px 5px !important;
          margin-right: 20px;
          margin-bottom: 10px;
          box-shadow: 0 0 10px rgba(127, 137, 167, 0.15);
          border-radius: 5px;
        }
        .button-remove {
          background: var(--support-danger-300) !important;
        }
      }
      table{
        display: flex;
        width: 100% !important;
        @media all and (max-width: 580px) {
          background: var(--additional-page-bg);
        }
      }
      .k-grid-header{
        tr{
          width: 100% !important;
          &:first-child{
            display: flex;
            flex-wrap: wrap;
            padding: 0 0 10px 0;
            border-bottom: 1px solid var(--secondary-300);
            margin-bottom: 10px;
            th{
              border: 0 !important;
              background: var(--white);
              box-shadow: 0 0 10px rgba(127, 137, 167, 0.15);;
              border-radius: 50px;
              padding: 7px;
              margin-right: 5px;
            }
            th[aria-sort]{
              background: var(--secondary-300);
              color: var(--white);
            }
          }
          &:last-child{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            td{
              width: 49%;
              padding: 0;
              margin-bottom: 12px;
              &:empty{
                display: none !important;
              }
            }
          }
        }
        @media all and (max-width: 580px) {
          color: var(--additional-grey-300);
          tr{
            width: 100% !important;
            &:first-child{
              @media all and (max-width: 580px) {
                border-bottom: none;
              }
              th {
                border: none !important;
                margin-right: 32px;
                box-shadow: none;
                background: var(--additional-page-bg);
              }

              th[aria-sort] {
                background: var(--additional-page-bg);
                color: var(--primary-300);
              }

              th[aria-sort] > span:nth-child(1) > span:nth-child(1) > span:nth-child(1) {
                color: var(--primary-300);
                margin-right: 10px;
              }
              th:nth-child(6) {
                margin-right: 30px;
              }
              th:nth-child(7) {
                margin-right: 15px;
              }
            }
          }
        }
      }
      tbody{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 5px;
        min-width: 100%;
        @media all and (max-width: 580px) {
          padding: 0;
          background: var(--additional-page-bg);
        }
        tr{
          @media all and (max-width: 580px) {
            background: var(--white);
            border-radius: 10px;
            padding: 0;
            & > td {
              display: flex;
              margin-bottom: 16px;
            }
            & > td > span:nth-child(1) {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 12px;
              line-height: 22px;
              color: var(--additional-grey-300);
              width: 45%;
            }
          }
          display: flex;
          flex-direction: column;
          width: 49%;
          box-shadow: 0 0 10px rgba(127, 137, 167, 0.15);;
          border-radius: 12px;
          margin-bottom: 15px;
          padding: 20px 10px 20px 20px;
          td{
            padding: 0;
            .td-content-wrapper{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 10px;
              button{
                width: auto !important;
                position: relative !important;
                box-shadow: 0 0 10px rgba(127, 137, 167, 0.15);;
                padding: 8px;
              }
            }
          }
        }
      }
      .k-grid-norecords{
        position: absolute;
        left: 50%;
        top: 50%;
        padding: 12px;
        transform: translate(-50%,-50%);
      }
    }
    @media all and (max-width: 580px) {
      .k-grid-header{
        tr{
          &:last-child{
            td{
              width: 100%;
            }
          }
        }
      }
      tbody{
        tr{
          width: 100%;
        }
      }
    }
  }
  &.table-with-filters{
    @media all and (max-width: 991px) {
      thead{
        display: none;
        flex-direction: column-reverse;
        width: 100% !important;
      }
    }
  }
  &.table-with-out-filters{
    @media all and (max-width: 991px) {
      thead{
        display: flex !important;
        flex-direction: column;
        width: 100% !important;
      }
      .k-grid-header{
        tr{
          &:last-child{
            display: none !important;
          }
        }
      }
    }
  }
  &.th-last-none{
    .k-grid-header{
      tr{
        &:first-child{
          th{
            &:last-child{
              display: block;
              border: none;
            }
          }
        }
      }
    }
  }
  &.isTableFilter{
    thead{
      @media all and (max-width: 991px) {
        display: flex;
        min-width: 100%;
      }
    }
  }
}

#request-table {
  #users.responsive-table {
    &.table-with-filters, &.table-with-out-filters {
      thead {
        & > tr:nth-child(2) > td:nth-child(4) {
          margin-bottom: 0;
          & > div {
            margin: 16px 0 10px;
          }
        }
      }
      tbody {
        tr {
          @media all and (max-width: 580px) {
            background: var(--white);
            td {
              padding: 0 16px;
            }
            td:first-child {
              font-size: 16px;
              height: 58px;
              line-height: 58px;
              padding-left: 16px;
              border-radius: 10px 10px 0 0;
              background: #EAF5F9;
            }
            & > td:last-child {
              margin-bottom: 0;
              background: var(--white);
              padding: 0 16px 16px;
              border-radius: 0 0 10px 10px;
            }
            & > td > span:nth-child(2) {
              width: 60%;
            }
            & > td:nth-child(5) > div {
              margin: 16px;
              width: 100%;
            }
            & > td:nth-child(5) > div > span {
              display: none;
            }
            & > td:nth-child(5) > div > button {
              height: 49px !important;
              width: 100% !important;
              background: var(--secondary-300) !important;
              box-shadow: 0 2px 9px rgba(0, 29, 16, 0.15);
              border-radius: 6px;
              font-weight: 700;
              font-size: 16px;
              line-height: 18px;
              text-align: center;
              color: var(--white) !important;

              & > img {
                display: none;
              }
            }
          }
        }
      }
      @media all and (max-width: 580px) {
        .k-grid-content {
          min-height: 320px;
          tbody {
            tr.k-grid-norecords {
              height: 100%;
              > td:first-child {
                height: 100%;
                .no-data {
                  .no-data-title {
                    margin-top: 15px;
                    line-height: 28px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #edit.responsive-table {
    &.table-with-filters, &.table-with-out-filters {
      thead {
        & > tr > td > div {
          margin-top: 0;
        }
      }
      @media all and (max-width: 580px) {
        .k-grid-header {
          tr:first-child {
            th {
              margin-right: 25px;
            }
            th:nth-child(6) {
              margin-right: 20px;
            }
            th:nth-child(7) {
              margin-right: 0;
            }
          }
        }
      }
      tbody {
        tr {
          @media all and (max-width: 580px) {
            background: var(--white);
            padding: 16px;
            & > td > span:nth-child(1) {
              width: 30%;
            }
            & > td:nth-child(8) {
              margin-bottom: 0;
              border-radius: 0 0 6px 6px;
            }
            & > td:nth-child(8) > div {
              width: 100%;
              padding: 0;
              margin: 16px 0 0;
              & > img {
                display: none;
              }
            }
            & > td:nth-child(8) > div > button {
              height: 49px !important;
              width: 100% !important;
              background: var(--secondary-300) !important;
              box-shadow: 0 2px 9px rgba(0, 29, 16, 0.15);
              border-radius: 6px;
              font-weight: 700;
              font-size: 16px;
              line-height: 18px;
              text-align: center;
              color: var(--white) !important;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 580px) {
    &.k-grid {
      padding: 0;
    }
  }
}

#users-tables {
  .responsive-table {
    &.table-with-filters, &.table-with-out-filters {
      @media all and (max-width: 580px) {
        .action-buttons {
          width: 100%;
          button{
            margin-right: 0;
            height: 49px;
            display: flex;
            align-items: center;
          }
        }
        thead {
          & > tr:nth-child(2) > td:nth-child(6) {
            margin-bottom: 0;
            & > div {
              margin: 16px 0 10px;
            }
          }
        }
        tbody {
          tr {
            @media all and (max-width: 580px) {
              background: var(--white);
              & > td {
                padding: 0 16px;
                margin-bottom: 14px;
              }
              & > td:first-child {
                border-radius: 10px 10px 0 0;
                background: #EAF5F9;
                padding: 10px 0 2px 16px;
                margin-bottom: 0;
                color: var(--tertiary-300);
              }
              & > td:nth-child(2) {
                font-size: 16px;
                min-height: 34px;
                padding: 0 0 0 16px;
                position: relative;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                background: #EAF5F9;
              }
              & > td:last-child {
                display: none !important;
              }
              &.k-grid-norecords {
                > td:last-child {
                  background: var(--white);
                  display: block !important;
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 580px) {
    .btn-filter {
      width: 100%;
      margin-left: 0;
    }
  }
}

#social-groups {
  .responsive-table {
    &.table-with-filters, &.table-with-out-filters {
      @media all and (max-width: 991px) {
        .k-grid-md td, .k-grid-md .k-table-td {
          height: 100%;
        }
        tbody {
          tr {
              & > td:first-child {
                display: none;
              }
              & > td:nth-child(2) {
                font-size: 16px;
                min-height: 34px;
                position: relative;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
              }
              &.k-grid-norecords {
                > td:last-child {
                  background: var(--white);
                  display: block !important;
                }
              }
            }
          }
      }
      @media all and (max-width: 580px) {
        .action-buttons {
          width: 100%;
          button{
            margin-right: 0;
            height: 49px;
            display: flex;
            align-items: center;
          }
        }
        thead {
          & > tr:nth-child(2) > td:nth-child(6) {
            margin-bottom: 0;
            & > div {
              margin: 16px 0 10px;
            }
          }
        }
        tbody {
          tr {
            @media all and (max-width: 580px) {
              background: var(--white);
              & > td {
                padding: 0 16px;
                margin-bottom: 14px;
              }
              & > td:first-child {
                border-radius: 10px 10px 0 0;
                background: #EAF5F9;
                padding: 10px 0 2px 16px;
                margin-bottom: 0;
                color: var(--tertiary-300);
              }
              & > td:nth-child(2) {
                font-size: 16px;
                min-height: 34px;
                padding: 0 0 0 16px;
                position: relative;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                background: #EAF5F9;
              }
              & > td:last-child {
                display: none !important;
              }
              &.k-grid-norecords {
                > td:last-child {
                  background: var(--white);
                  display: block !important;
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 580px) {
    .btn-filter {
      width: 100%;
      margin-left: 0;
    }
  }
}

#journal-remark-table {
  &.responsive-table {
    &.table-with-filters {
      thead {
        & > tr:first-child > th:nth-child(1) {
          display: none;
        }
      }
      tbody {
        tr {
          @media all and (max-width: 580px) {
            background: var(--white);
            & > td:first-child {
              margin-bottom: 0;
              border-radius: 10px 10px 0 0;
              background: #EAF5F9;
              padding-left: 16px;
              color: var(--purple-dark);
              span:nth-child(1) {
                max-width: 33%;
                min-width: 33%;
              }
            }
            & > td:nth-child(2) {
              margin-bottom: 0;
              background: #EAF5F9;
              padding-left: 16px;
              color: var(--purple-dark);
              span:nth-child(1) {
                max-width: 26%;
                min-width: 26%;
              }
            }
            & > td:nth-child(3) {
              background: #EAF5F9;
              padding: 0 0 12px 16px;
              color: var(--purple-dark);
              span:first-child {
                display: none;
              }
            }
            & > td {
              padding: 0 16px;
              span:first-child {
                min-width: 80px;
                max-width: 80px;
                width: 80px;
              }
            }
            & > td > span:nth-child(1) {
              margin-right: 5px;
            }
            & > td:nth-child(6) > a {
              height: 22px;
              max-width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 580px) {
    .btn-filter {
      width: 100%;
      margin-left: 0;
    }
    &.k-grid {
      padding: 0;
    }
  }
}

.mb-15{
  margin-bottom: 15px;
}

.sticky-left-table-bar{
  .lesson-teacher{
    left: 0;
    z-index: 11 !important;
  }
  .teacher-name{
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .tbody{
    tr{
      position: relative;
    }
  }
}
#journal-table{
  tbody .student{
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 1;
    background: var(--white);
  }
}
nit-student-schedule-table{
  table > tbody > tr > td:first-child{
    position: sticky;
    left: 0;
  }
}

#class-users-grid{
  @media all and (max-width: 991px) {
    tbody{
      > tr{
        position: relative;
      }
    }
    .k-grid-header tr:last-child{
      justify-content: flex-start;
      padding-top: 12px;
      border: 0 !important;
      margin-bottom: 0;
      th{
        &:last-child,&:nth-last-child(2){
          display: none;
        }
      }
    }
    .button-remove{
      right: 15px;
      position: absolute;
      top: 15px;
      z-index: 1;
    }
  }
  @media all and (max-width: 580px) {
    .context-btn {
      min-height: 45px;
      button {
        padding-top: 0;
        display: flex;
        align-items: flex-start;
        svg {
          height: 10px;
        }
      }
    }
    &.responsive-table {
      .k-grid-header {
        tr {
          &:first-child {
            th {
              border: none !important;
              margin-right: 20px;
              box-shadow: none;
              background: var(--additional-page-bg);
            }
          }
          &:last-child {
            padding-top: 0;
          }
        }
      }
    }
    .action-buttons {
      button{
        margin-right: 0;
        height: 49px;
        display: flex;
        align-items: center;
      }
    }
    thead {
      & > tr:nth-child(2) > td:nth-child(6) {
        margin-bottom: 0;
        & > div {
          margin: 16px 0 10px;
        }
      }
    }
    tbody {
      tr {
        @media all and (max-width: 580px) {
          background: var(--white);
          & > td > span:nth-child(1) {
            width: 35%;
          }
          & > td {
            padding: 0 16px;
            margin-bottom: 14px;
          }
          & > td:first-child {
            border-radius: 10px 10px 0 0;
            background: #EAF5F9;
            padding: 10px 0 2px 16px;
            margin-bottom: 0;
            color: var(--tertiary-300);
          }
          & > td:nth-child(2) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            font-size: 16px;
            min-height: 34px;
            padding: 0 0 0 16px;
            position: relative;
            background: #EAF5F9;
            & > span {
              display: none;
            }
          }
          & > td:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

#class-profile-tabstrip {
  .btn-filter {
    margin-bottom: 0;
  }
}
