// Splitter
$kendo-splitter-border-width: 1px !default;
$kendo-splitter-font-family: $kendo-font-family !default;
$kendo-splitter-font-size: $kendo-font-size-md !default;
$kendo-splitter-line-height: $kendo-line-height-md !default;

$kendo-splitter-bg: $kendo-component-bg !default;
$kendo-splitter-text: $kendo-component-text !default;
$kendo-splitter-border: $kendo-component-border !default;

$kendo-splitter-splitbar-size: 8px !default;
$kendo-splitter-resize-icon-size: 10px !default;
$kendo-splitter-drag-handle-length: 20px !default;
$kendo-splitter-drag-handle-thickness: 2px !default;
$kendo-splitter-drag-icon-margin: 7px !default;

$kendo-splitbar-bg: $kendo-base-bg !default;
$kendo-splitbar-text: $kendo-base-text !default;

$kendo-splitbar-hover-bg: k-try-shade( $kendo-splitbar-bg, .5 ) !default;
$kendo-splitbar-hover-text: $kendo-splitbar-text !default;

$kendo-splitbar-selected-bg: $kendo-selected-bg !default;
$kendo-splitbar-selected-text: $kendo-selected-text !default;
