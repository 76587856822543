// Dropzone

$kendo-dropzone-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-dropzone-padding-y: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-dropzone-border-width: 1px !default;
$kendo-dropzone-min-height: 220px !default;

$kendo-dropzone-font-family: $kendo-font-family !default;
$kendo-dropzone-font-size: $kendo-font-size-md !default;
$kendo-dropzone-line-height: $kendo-line-height-md !default;

$kendo-dropzone-bg: $kendo-base-bg !default;
$kendo-dropzone-text: $kendo-base-text !default;
$kendo-dropzone-border: $kendo-base-border !default;

$kendo-dropzone-icon-size: ( $kendo-icon-size * 3 ) !default;
$kendo-dropzone-icon-spacing: k-map-get( $kendo-spacing, 6 ) !default;
$kendo-dropzone-icon-text: k-try-tint( $kendo-dropzone-text, 4 ) !default;
$kendo-dropzone-icon-hover-text: $kendo-color-primary !default;

$kendo-dropzone-hint-font-size: null !default;
$kendo-dropzone-hint-spacing: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-dropzone-hint-text: null !default;

$kendo-dropzone-note-font-size: $kendo-font-size-sm !default;
$kendo-dropzone-note-spacing: null !default;
$kendo-dropzone-note-text: $kendo-subtle-text !default;
