@mixin kendo-loader--theme-base() {

    @each $name, $color in $kendo-theme-colors {
        .k-loader-#{$name} {
            @if $name == "secondary" {
                color: $kendo-loader-secondary-bg;
            } @else {
                color: $color;
            }
        }
    }

    .k-loader-container-panel {
        background-color: $kendo-loader-container-panel-bg;
        border-color: $kendo-loader-container-panel-border-color;
    }

    .k-loader-container-inner {
        // sass-lint:disable-block no-important
        // sass-lint:disable-block class-name-format
        .k-text-secondary,
        .\!k-text-secondary {
            color: $kendo-loader-secondary-bg !important;
        }
    }

}


@mixin kendo-loader--theme() {
    @include kendo-loader--theme-base();
}
