/// This is equivalent to `justify-content: normal;`.
/// @example justify-content: normal;
/// @name .k-justify-content-normal
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: stretch;`.
/// @example justify-content: stretch;
/// @name .k-justify-content-stretch
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: center;`.
/// @example justify-content: center;
/// @name .k-justify-content-center
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: start;`.
/// @example justify-content: start;
/// @name .k-justify-content-start
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: end;`.
/// @example justify-content: end;
/// @name .k-justify-content-end
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: flex-start;`.
/// @example justify-content: flex-start;
/// @name .k-justify-content-selft-start
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: flex-end;`.
/// @example justify-content: flex-end;
/// @name .k-justify-content-self-end
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: baseline;`.
/// @example justify-content: baseline;
/// @name .k-justify-content-baseline
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: first baseline;`.
/// @example justify-content: first baseline;
/// @name .k-justify-content-first-baseline
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: last baseline;`.
/// @example justify-content: last baseline;
/// @name .k-justify-content-last-baseline
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: space-between;`.
/// @example justify-content: space-between;
/// @name .k-justify-content-between
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: space-around;`.
/// @example justify-content: space-around;
/// @name .k-justify-content-around
/// @group justify-content
/// @contextType css

/// This is equivalent to `justify-content: space-evenly;`.
/// @example justify-content: space-evenly;
/// @name .k-justify-content-evenly
/// @group justify-content
/// @contextType css

@mixin kendo-utils--flex-grid--justify-content() {

    // Justify content utility classes
    $kendo-utils-justify-content: k-map-get( $kendo-utils, "justify-content" ) !default;
    @include generate-utils( justify-content, justify-content, $kendo-utils-justify-content );


    // Custom justify-content classes
    .#{$kendo-prefix}justify-content-stretch > * { flex: 1 0 0%; }
    .\!.#{$kendo-prefix}justify-content-stretch > * { flex: 1 0 0% !important; } // sass-lint:disable-line no-important class-name-format

}
