// Calendar

$kendo-calendar-border-width: 1px !default;
$kendo-calendar-font-family: $kendo-font-family !default;
$kendo-calendar-font-size: $kendo-font-size-md !default;
$kendo-calendar-line-height: $kendo-line-height-md !default;

$kendo-calendar-cell-size: 32px !default;

$kendo-calendar-bg: $kendo-component-bg !default;
$kendo-calendar-text: $kendo-component-text !default;
$kendo-calendar-border: $kendo-component-border !default;

$kendo-calendar-header-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-calendar-header-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-calendar-header-border-width: 1px !default;

$kendo-calendar-header-bg: $kendo-component-header-bg !default;
$kendo-calendar-header-text: $kendo-component-header-text !default;
$kendo-calendar-header-border: $kendo-component-header-border !default;
$kendo-calendar-header-gradient: $kendo-component-header-gradient !default;
$kendo-calendar-header-shadow: 0 1px 3px 1px rgba(black, .1) !default;

$kendo-calendar-nav-gap: null !default;

$kendo-calendar-footer-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-calendar-footer-padding-y: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-calendar-cell-padding-x: .25em !default;
$kendo-calendar-cell-padding-y: $kendo-calendar-cell-padding-x !default;
$kendo-calendar-cell-line-height: $kendo-calendar-line-height !default;
$kendo-calendar-cell-border-radius: $kendo-border-radius-md !default;

$kendo-calendar-header-cell-padding-x: null !default;
$kendo-calendar-header-cell-padding-y: null !default;
$kendo-calendar-header-cell-width: $kendo-calendar-cell-size !default;
$kendo-calendar-header-cell-height: $kendo-calendar-cell-size !default;
$kendo-calendar-header-cell-font-size: $kendo-font-size-sm !default;
$kendo-calendar-header-cell-line-height: 2 !default;
$kendo-calendar-header-cell-bg: null !default;
$kendo-calendar-header-cell-text: $kendo-subtle-text !default;
$kendo-calendar-header-cell-opacity: null !default;

$kendo-calendar-caption-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-calendar-caption-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-calendar-caption-height: $kendo-calendar-cell-size !default;
$kendo-calendar-caption-font-size: null !default;
$kendo-calendar-caption-line-height: null !default;
$kendo-calendar-caption-font-weight: bold !default;

$kendo-calendar-view-width: ($kendo-calendar-cell-size * 8) !default;
$kendo-calendar-view-height: ($kendo-calendar-cell-size * 7) !default;
$kendo-calendar-view-gap: k-map-get( $kendo-spacing, 4 ) !default;

$kendo-calendar-weekend-bg: null !default;
$kendo-calendar-weekend-text: null !default;

$kendo-calendar-today-style: color !default;
$kendo-calendar-today-color: $kendo-color-primary !default;

$kendo-calendar-week-number-bg: null !default;
$kendo-calendar-week-number-text: $kendo-calendar-header-cell-text !default;

$kendo-calendar-other-month-bg: null !default;
$kendo-calendar-other-month-text: $kendo-calendar-header-cell-text !default;

$kendo-calendar-cell-bg: null !default;
$kendo-calendar-cell-text: null !default;
$kendo-calendar-cell-border: null !default;
$kendo-calendar-cell-gradient: null !default;

$kendo-calendar-cell-hover-bg: $kendo-hover-bg !default;
$kendo-calendar-cell-hover-text: $kendo-hover-text !default;
$kendo-calendar-cell-hover-border: $kendo-hover-border !default;
$kendo-calendar-cell-hover-gradient: null !default;

$kendo-calendar-cell-selected-bg: $kendo-selected-bg !default;
$kendo-calendar-cell-selected-text: $kendo-selected-text !default;
$kendo-calendar-cell-selected-border: $kendo-selected-border !default;
$kendo-calendar-cell-selected-gradient: null !default;

$kendo-calendar-cell-selected-hover-bg: $kendo-selected-hover-bg !default;
$kendo-calendar-cell-selected-hover-text: $kendo-selected-hover-text !default;
$kendo-calendar-cell-selected-hover-border: null !default;
$kendo-calendar-cell-selected-hover-gradient: null !default;

$kendo-calendar-cell-focus-shadow: inset 0 0 0 2px rgba( $kendo-color-black, .08 ) !default;
$kendo-calendar-cell-selected-focus-shadow: $kendo-calendar-cell-focus-shadow !default;


// Calendar navigation
$kendo-calendar-navigation-width: 5em !default;
$kendo-calendar-navigation-item-height: 2em !default;

$kendo-calendar-navigation-bg: $kendo-calendar-header-bg !default;
$kendo-calendar-navigation-text: $kendo-calendar-header-text !default;
$kendo-calendar-navigation-border: $kendo-calendar-header-border !default;

$kendo-calendar-navigation-hover-text: $kendo-link-hover-text !default;


// Infinite calendar
$kendo-infinite-calendar-header-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-infinite-calendar-header-padding-y: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-infinite-calendar-view-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-infinite-calendar-view-padding-y: 0px !default;
$kendo-infinite-calendar-view-height: ( $kendo-calendar-cell-size * 9 ) !default;


// Multiview calendar


// Calendar sizes
$kendo-calendar-sm-font-size: $kendo-font-size-md !default;
$kendo-calendar-sm-line-height: $kendo-line-height-md !default;
$kendo-calendar-sm-cell-size: 28px !default;
$kendo-calendar-sm-cell-padding-x: k-map-get( $kendo-spacing, 0.5 ) !default; // sass-lint:disable-line leading-zero
$kendo-calendar-sm-cell-padding-y: k-map-get( $kendo-spacing, 0.5 ) !default; // sass-lint:disable-line leading-zero

$kendo-calendar-md-font-size: $kendo-font-size-md !default;
$kendo-calendar-md-line-height: $kendo-line-height-md !default;
$kendo-calendar-md-cell-size: 32px !default;
$kendo-calendar-md-cell-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-calendar-md-cell-padding-y: k-map-get( $kendo-spacing, 1 ) !default;

$kendo-calendar-lg-font-size: $kendo-font-size-lg !default;
$kendo-calendar-lg-line-height: $kendo-line-height-lg !default;
$kendo-calendar-lg-cell-size: 40px !default;
$kendo-calendar-lg-cell-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-calendar-lg-cell-padding-y: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-calendar-sizes: (
    sm: (
        font-size: $kendo-calendar-sm-font-size,
        line-height: $kendo-calendar-sm-line-height,
        cell-size: $kendo-calendar-sm-cell-size,
        cell-padding-x: $kendo-calendar-sm-cell-padding-y,
        cell-padding-y: $kendo-calendar-sm-cell-padding-x
    ),
    md: (
        font-size: $kendo-calendar-md-font-size,
        line-height: $kendo-calendar-md-line-height,
        cell-size: $kendo-calendar-md-cell-size,
        cell-padding-x: $kendo-calendar-md-cell-padding-y,
        cell-padding-y: $kendo-calendar-md-cell-padding-x
    ),
    lg: (
        font-size: $kendo-calendar-lg-font-size,
        line-height: $kendo-calendar-lg-line-height,
        cell-size: $kendo-calendar-lg-cell-size,
        cell-padding-x: $kendo-calendar-lg-cell-padding-y,
        cell-padding-y: $kendo-calendar-lg-cell-padding-x
    )
) !default;
