@mixin kendo-dialog--theme-base() {

    // Dialog titlebar
    .k-dialog-titlebar {
        @include fill(
            $kendo-dialog-titlebar-text,
            $kendo-dialog-titlebar-bg,
            $kendo-dialog-titlebar-border
        );
    }

    // Dialog theme colors
    @each $name, $color in $kendo-dialog-theme-colors {
        .k-dialog-#{$name} .k-dialog-titlebar {
            color: k-contrast-legacy( $color );
            background-color: $color;
        }
    }

}


@mixin kendo-dialog--theme() {
    @include kendo-dialog--theme-base();
}
