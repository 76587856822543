@mixin generate-utils( $name, $props, $values, $function: "", $important: $kendo-important ) {
    @if $values {
        $_props: if( k-meta-type-of($props) == list, $props, ( $props ) );
        $_fn: if( k-meta-function-exists( $function ), k-meta-get-function( $function ), null );

        @each $key, $val in $values {
            $_key: k-escape-class-name( $key );
            $_val: if( k-meta-type-of($values) == list, $key, $val );
            $_name: k-escape-class-name( $name );
            $_selector: if( $_key == DEFAULT, #{$kendo-prefix}#{$_name}, #{$kendo-prefix}#{$_name}-#{$_key});

            @if $important != only {
                .#{$_selector} {
                    @each $prop in $_props {
                        #{$prop}: if( $_fn, k-meta-call($_fn, $_val), $_val );
                    }
                }
            }
            @if $important {
                .\!#{$_selector} {
                    @each $prop in $_props {
                        #{$prop}:  if( $_fn, k-meta-call($_fn, $_val), $_val ) !important; // sass-lint:disable-line no-important
                    }
                }
            }
        }
    }
}
