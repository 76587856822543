// Listbox

/// Margin between the listbox elements.
/// @group listbox
$kendo-listbox-spacing: k-map-get( $kendo-spacing, 2 ) !default;
/// Margin between the listbox buttons.
/// @group listbox
$kendo-listbox-button-spacing: k-map-get( $kendo-spacing, 2 ) !default;
/// Width of the listbox.
/// @group listbox
$kendo-listbox-width: 10em !default;
/// Height of the listbox.
/// @group listbox
$kendo-listbox-default-height: 200px !default;
/// Width of the border around the listbox.
/// @group listbox
$kendo-listbox-border-width: 1px !default;
/// Font family of the listbox.
/// @group listbox
$kendo-listbox-font-family: $kendo-font-family !default;
/// Font size of the listbox.
/// @group listbox
$kendo-listbox-font-size: $kendo-font-size-md !default;
/// Line height of the listbox.
/// @group listbox
$kendo-listbox-line-height: $kendo-line-height-md !default;
/// Background color of the listbox.
/// @group listbox
$kendo-listbox-bg: $kendo-component-bg !default;
/// Text color of the listbox.
/// @group listbox
$kendo-listbox-text: $kendo-component-text !default;
/// Border color of the listbox.
/// @group listbox
$kendo-listbox-border: $kendo-component-border !default;

/// Inline item padding of the listbox.
/// @group listbox
$kendo-listbox-item-padding-x: null !default;
/// Block item padding of the listbox.
/// @group listbox
$kendo-listbox-item-padding-y: null !default;

/// Width of the border around the drop hint.
/// @group listbox
$kendo-listbox-drop-hint-border-width: null !default;
/// Width of the drop hint.
/// @group listbox
$kendo-listbox-drop-hint-width: 1px !default;
