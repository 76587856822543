// Loader

$kendo-loader-segment-border-radius: 50% !default;
$kendo-loader-sm-segment-size: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-loader-md-segment-size: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-loader-lg-segment-size: k-map-get( $kendo-spacing, 4 ) !default;

$kendo-loader-sm-padding: k-math-div( $kendo-loader-sm-segment-size, 2 ) !default;
$kendo-loader-md-padding: k-math-div( $kendo-loader-md-segment-size, 2 ) !default;
$kendo-loader-lg-padding: k-math-div( $kendo-loader-lg-segment-size, 2 ) !default;

$kendo-loader-sm-spinner-3-width: ( $kendo-loader-sm-segment-size * 4 ) !default;
$kendo-loader-sm-spinner-3-height: ( $kendo-loader-sm-spinner-3-width * $equilateral-height ) !default;
$kendo-loader-md-spinner-3-width: ( $kendo-loader-md-segment-size * 4 ) !default;
$kendo-loader-md-spinner-3-height: ( $kendo-loader-md-spinner-3-width * $equilateral-height ) !default;
$kendo-loader-lg-spinner-3-width: ( $kendo-loader-lg-segment-size * 4 ) !default;
$kendo-loader-lg-spinner-3-height: ( $kendo-loader-lg-spinner-3-width * $equilateral-height ) !default;

$kendo-loader-sm-spinner-4-width: $kendo-loader-sm-segment-size * 4 !default;
$kendo-loader-sm-spinner-4-height: $kendo-loader-sm-spinner-4-width !default;
$kendo-loader-md-spinner-4-width: $kendo-loader-md-segment-size * 4 !default;
$kendo-loader-md-spinner-4-height: $kendo-loader-md-spinner-4-width !default;
$kendo-loader-lg-spinner-4-width: $kendo-loader-lg-segment-size * 4 !default;
$kendo-loader-lg-spinner-4-height: $kendo-loader-lg-spinner-4-width !default;

$kendo-loader-secondary-bg: #656565 !default;

$kendo-loader-container-panel-border-width: 1px !default;
$kendo-loader-container-panel-border-style: solid !default;
$kendo-loader-container-panel-border-color: $kendo-component-border !default;
$kendo-loader-container-panel-border-radius: $kendo-border-radius-md !default;
$kendo-loader-container-panel-bg: $kendo-color-white !default;

$kendo-loader-sm-container-padding: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-loader-sm-container-gap: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-loader-sm-container-font-size: $kendo-font-size-sm !default;

$kendo-loader-md-container-padding: k-map-get( $kendo-spacing, 5 ) !default;
$kendo-loader-md-container-gap: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-loader-md-container-font-size: $kendo-font-size-md !default;

$kendo-loader-lg-container-padding: k-map-get( $kendo-spacing, 6 ) !default;
$kendo-loader-lg-container-gap: k-map-get( $kendo-spacing, 3 ) !default;
$kendo-loader-lg-container-font-size: $kendo-font-size-lg !default;


// Loading
$kendo-loading-bg: $kendo-component-bg !default;
$kendo-loading-text: currentColor !default;
$kendo-loading-opacity: .3 !default;
