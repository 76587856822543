// Tooltip
$kendo-tooltip-padding-y: $kendo-padding-md-y !default;
$kendo-tooltip-padding-x: $kendo-padding-md-x !default;
$kendo-tooltip-max-width: null !default;
$kendo-tooltip-border-width: 0px !default;
$kendo-tooltip-border-radius: $kendo-border-radius-md !default;

$kendo-tooltip-font-family: $kendo-font-family !default;
$kendo-tooltip-font-size: $kendo-font-size-md !default;
$kendo-tooltip-line-height: 1.25 !default;

$kendo-tooltip-title-font-size: ($kendo-tooltip-font-size * 1.25) !default;
$kendo-tooltip-title-line-height: 1 !default;

$kendo-tooltip-callout-size: 6px !default;

$kendo-tooltip-bg: rgba( k-contrast-legacy( $kendo-body-bg ), .75 ) !default;
$kendo-tooltip-text: k-contrast-legacy( $kendo-tooltip-bg ) !default;
$kendo-tooltip-border: $kendo-tooltip-bg !default;

$kendo-tooltip-primary-bg: $kendo-color-primary !default;
$kendo-tooltip-primary-text: k-contrast-legacy( $kendo-tooltip-primary-bg ) !default;
$kendo-tooltip-primary-border: $kendo-tooltip-primary-bg !default;
$kendo-tooltip-info-bg: $kendo-color-info !default;
$kendo-tooltip-info-text: k-contrast-legacy( $kendo-tooltip-info-bg ) !default;
$kendo-tooltip-info-border: $kendo-tooltip-info-bg !default;
$kendo-tooltip-success-bg: $kendo-color-success !default;
$kendo-tooltip-success-text: k-contrast-legacy( $kendo-tooltip-success-bg ) !default;
$kendo-tooltip-success-border: $kendo-tooltip-success-bg !default;
$kendo-tooltip-warning-bg: $kendo-color-warning !default;
$kendo-tooltip-warning-text: k-contrast-legacy( $kendo-tooltip-warning-bg ) !default;
$kendo-tooltip-warning-border: $kendo-tooltip-warning-bg !default;
$kendo-tooltip-error-bg: $kendo-color-error !default;
$kendo-tooltip-error-text: k-contrast-legacy( $kendo-tooltip-error-bg ) !default;
$kendo-tooltip-error-border: $kendo-tooltip-error-bg !default;
