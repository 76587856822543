// Dialog
$kendo-dialog-titlebar-bg: $kendo-component-header-bg !default;
$kendo-dialog-titlebar-text: $kendo-component-header-text !default;
$kendo-dialog-titlebar-border: inherit !default;

$kendo-dialog-buttongroup-padding-x: $kendo-actions-padding-x !default;
$kendo-dialog-buttongroup-padding-y: $kendo-actions-padding-y !default;
$kendo-dialog-buttongroup-border-width: 1px !default;

$kendo-dialog-button-spacing: $kendo-actions-button-spacing !default;

/// Theme colors map for the dialog.
/// @group dialog
$kendo-dialog-theme-colors: (
    "primary": k-map-get($kendo-theme-colors, "primary"),
    "light": k-map-get($kendo-theme-colors, "light"),
    "dark": k-map-get($kendo-theme-colors, "dark")
) !default;
