// Notification

/// Vertical padding of the notification container.
/// @group notification
$kendo-notification-padding-x: 8px !default;
/// Horizontal padding of the notification.
/// @group notification
$kendo-notification-padding-y: 4px !default;
/// Width of the border around the notification.
/// @group notification
$kendo-notification-border-width: 1px !default;
/// Border radius of the notification.
/// @group notification
$kendo-notification-border-radius: $kendo-border-radius-md !default;
/// Box shadow of the notification.
/// @group notification
$kendo-notification-shadow: $kendo-popup-shadow !default;
/// Font family of the notification.
/// @group notification
$kendo-notification-font-family: $kendo-font-family !default;
/// Font size of the notification.
/// @group notification
$kendo-notification-font-size: $kendo-font-size-sm !default;
/// Line height of the notification.
/// @group notification
$kendo-notification-line-height: $kendo-line-height-md !default;

/// Horizontal spacing of the notification icon.
/// @group notification
$kendo-notification-icon-spacing: $kendo-icon-spacing !default;

/// Background color of the notification.
/// @group notification
$kendo-notification-bg: $kendo-component-bg !default;
/// Text color of the notification.
/// @group notification
$kendo-notification-text: $kendo-component-text !default;
/// Border color of the notification.
/// @group notification
$kendo-notification-border: $kendo-component-border !default;

@function notification-theme( $colors ) {
    $_theme: ();

    // sass-lint:disable-block indentation
    @each $name, $color in $colors {
        $_theme: k-map-merge(( $name: (
            color: k-contrast-legacy( $color ),
            background-color: $color,
            border: $color,
        )), $_theme );
    }

    @return $_theme;
}

/// Theme colors of the notification.
/// @group notification
$kendo-notification-theme-colors: $kendo-theme-colors !default;
$kendo-notification-theme: notification-theme( $kendo-notification-theme-colors ) !default;
