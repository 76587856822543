@mixin kendo-core--styles--normalize() {

    // Hidden attribute
    [hidden] {
        display: none !important; // sass-lint:disable-line no-important
    }


    // Hide script tags
    // see https://github.com/telerik/kendo-themes/issues/2360
    script {
        display: none !important; // sass-lint:disable-line no-important
    }

}
