// Floating label
$kendo-floating-label-scale: 1 !default;
$kendo-floating-label-font-size: $kendo-input-font-size !default;
$kendo-floating-label-max-width: 90% !default;
$kendo-floating-label-line-height: $kendo-input-line-height !default;
$kendo-floating-label-height: calc( #{$kendo-floating-label-line-height} * #{$kendo-floating-label-font-size} ) !default;
$kendo-floating-label-offset-x: calc( #{$kendo-input-padding-x} + #{$kendo-input-border-width} ) !default;
$kendo-floating-label-offset-y: calc( #{$kendo-floating-label-height} + #{$kendo-input-border-width} + #{$kendo-input-padding-y} ) !default;

$kendo-floating-label-focus-scale: 1 !default;
$kendo-floating-label-focus-offset-x: 0 !default;
$kendo-floating-label-focus-offset-y: 0 !default;

$kendo-floating-label-transition: .2s ease-out !default;

$kendo-floating-label-bg: null !default;
$kendo-floating-label-text: null !default;

$kendo-floating-label-focus-bg: null !default;
$kendo-floating-label-focus-text: null !default;
