// Colorpalette
$kendo-color-palette-font-family: $kendo-font-family !default;
$kendo-color-palette-font-size: $kendo-font-size-md !default;
$kendo-color-palette-line-height: 0 !default;

$kendo-color-palette-tile-width: k-map-get( $kendo-spacing, 6 ) !default;
$kendo-color-palette-tile-height: $kendo-color-palette-tile-width !default;
$kendo-color-palette-tile-focus-shadow: 0 0 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, .5 ) !default;
$kendo-color-palette-tile-hover-shadow: 0 0 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, .8 ) !default;
$kendo-color-palette-tile-selected-shadow: 0 1px 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, 1 ) !default;
